import React, { Component } from 'react';
import { navigate } from 'gatsby';
import { getPreviewText } from './actions/find-game-text';

class GamePreview extends Component {
  handleImageSelect(image) {
    // Add Server Call Here To Save Parial Game (can be used to send emails after)
    navigate(this.props.redirect, {
      state: {
        heroImage: image,
      },
    });
  }

  render() {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ width: '50%', margin: '3rem 1rem' }}>
          <div style={{
            width: '95%', height: '0px', position: 'relative', paddingBottom: '56.338%', margin: 'auto',
          }}
          >
            <iframe
              src={`https://streamable.com/e/${this.props.videoId}`}
              frameBorder="0"
              width="100%"
              height="100%"
              allowFullScreen
              allow="autoplay"
              style={{
                width: '100%', height: '100%', position: 'absolute', left: '0px', top: '0px', overflow: 'hidden',
              }}
            />
          </div>
          <input id="pixopixa-hero-select" type="file" style={{ visibility: 'hidden' }} onChange={(e) => { this.handleImageSelect(e.target.files[0]); }} />
        </div>
        <div style={{ width: '50%', padding: '1rem 2.5rem', textAlign: 'center' }}>
          {getPreviewText(this.props.gameVersionId)}
          <br />
          <br />
          <div style={{ width: '100%', textAlign: 'center' }}>
            <a className="cta-button" style={{ marginBottom: '0.5rem' }} onClick={() => { document.getElementById('pixopixa-hero-select').click(); }}>Select Your Hero</a>
          </div>
        </div>
      </div>
    );
  }
}

export default GamePreview;
