import React from 'react';
import { Router } from '@reach/router';
import Layout from '../components/layout';
import GamePreview from '../components/game-preview';

const GameGenerationRouter = () => (
  <Layout>
    <Router>
      <GamePreview exact path="/game-preview/holly-halls-hopper" videoId="hputvb" redirect="/generate-game/holly-halls-hopper/player" gameVersionId="1" />
      <GamePreview exact path="/game-preview/birthday-bonanza" videoId="hputvb" redirect="/generate-game/birthday-bonanza/player" gameVersionId="2" />
      <GamePreview exact path="/game-preview/to-birthday-and-beyond" videoId="hputvb" redirect="/generate-game/to-birthday-and-beyond/player" gameVersionId="3" />
      <GamePreview exact path="/game-preview/workshop-dash" videoId="hputvb" redirect="/generate-game/workshop-dash/player" gameVersionId="4" />
      <GamePreview exact path="/game-preview/adventures-of-you" videoId="hputvb" redirect="/generate-game/adventures-of-you/player" gameVersionId="5" />
      <GamePreview exact path="/game-preview/beat-the-ball-drop" videoId="hputvb" redirect="/generate-game/beat-the-ball-drop/player" gameVersionId="6" />
      <GamePreview exact path="/game-preview/cupids-gauntlet" videoId="hputvb" redirect="/generate-game/cupids-gauntlet/player" gameVersionId="7" />
      <GamePreview exact path="/game-preview/birthday-derby" videoId="hputvb" redirect="/generate-game/birthday-derby/player" gameVersionId="8" />
      <GamePreview exact path="/game-preview/christmas-slider" videoId="hputvb" redirect="/generate-game/christmas-slider/player" gameVersionId="9" />
      <GamePreview exact path="/game-preview/beach-birthday" videoId="hputvb" redirect="/generate-game/beach-birthday/player" gameVersionId="10" />
      <GamePreview exact path="/game-preview/valentines-date-dash" videoId="hputvb" redirect="/generate-game/valentines-date-dash/player" gameVersionId="11" />
      <GamePreview exact path="/game-preview/princess-yous-perils" videoId="hputvb" redirect="/generate-game/princess-yous-perils/player" gameVersionId="12" />
    </Router>
  </Layout>
);

export default GameGenerationRouter;
